const datasource_ru58841_topical = [
  // Hemia Cosmetics
  {
    key: "hc1",
    product: "RU58841 topical",
    from: "Hemia Cosmetics",
    brand: "Rugen",
    price: "38.79",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/rugen/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "hc2",
    product: "RU58841 topical",
    from: "Hemia Cosmetics",
    brand: "Ruminagen",
    price: "45.26",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/ruminagen-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "hc3",
    product: "RU58841 topical",
    from: "Hemia Cosmetics",
    brand: "Rufinagen",
    price: "37.71",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/rufinagen-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "1",
    product: "RU58841 topical",
    brand: "RU58841",
    from: "Actifolic",
    price: "71.28",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://actifolic.com/product/actifolic-ru58841-solution/?wpam_id=146",
    provider_link: "https://actifolic.com/?wpam_id=146",
  },
  {
    key: "2",
    product: "RU58841 topical",
    brand: "RU58841",
    from: "Actifolic",
    price: "93.25",
    quantity: "50",
    concentration: "8",
    shipping: ["WORLD"],
    link: "https://actifolic.com/product/actifolic-ru58841-solution/?wpam_id=146",
    provider_link: "https://actifolic.com/?wpam_id=146",
  },
  {
    key: "3",
    product: "RU58841 topical",
    brand: "RuDerma",
    from: "MinoxidilMax",
    price: "60",
    quantity: "60",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.minoxidilmax.com/ru58841",
    provider_link: "https://www.minoxidilmax.com/products",
  },
  {
    key: "4",
    product: "RU58841 topical",
    brand: "RU-58841",
    from: "Chemyo",
    price: "69.99",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.chemyo.com/ru58841/",
    provider_link: "https://www.chemyo.com/",
  },
  {
    key: "5",
    product: "RU58841 topical",
    brand: "RU58841",
    from: "Anageninc",
    price: "39.95",
    quantity: "30",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://anageninc.com/ru58841-solution-50ml.html",
    provider_link: "https://anageninc.com/",
  },
  {
    key: "5.1",
    product: "RU58841 topical",
    brand: "RU58841",
    from: "Anageninc",
    price: "49.95",
    quantity: "30",
    concentration: "8",
    shipping: ["WORLD"],
    link: "https://anageninc.com/ru58841-solution-50ml.html",
    provider_link: "https://anageninc.com/",
  },
];

export default datasource_ru58841_topical;

const datasource_minoxidil_topical = [
  // Hemia Cosmetics
  {
    key: "hc1",
    product: "Minoxidil topical",
    from: "Hemia Cosmetics",
    brand: "Femagen",
    price: "30.17",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/femagen/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "hc2",
    product: "Minoxidil topical",
    from: "Hemia Cosmetics",
    brand: "Finagen",
    price: "25.86",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/finagen-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "hc3",
    product: "Minoxidil topical",
    from: "Hemia Cosmetics",
    brand: "Finagen+",
    price: "33.40",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/finagen-plus-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "hc4",
    product: "Minoxidil topical",
    from: "Hemia Cosmetics",
    brand: "Minabeard",
    price: "24.78",
    quantity: "50",
    concentration: "6",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/minabeard-50g/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "hc5",
    product: "Minoxidil topical",
    from: "Hemia Cosmetics",
    brand: "Minacream",
    price: "42.02",
    quantity: "50",
    concentration: "16",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/minacream/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "hc6",
    product: "Minoxidil topical",
    from: "Hemia Cosmetics",
    brand: "Ruminagen",
    price: "45.26",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/ruminagen-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "hc7",
    product: "Minoxidil topical",
    from: "Hemia Cosmetics",
    brand: "Rufinagen",
    price: "37.71",
    quantity: "50",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/rufinagen-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  // ReliableRxPharmacy
  {
    key: "rx1",
    product: "Minoxidil topical",
    brand: "Tugain",
    from: "ReliableRxPharmacy",
    price: "23",
    quantity: "60",
    concentration: "10",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=11977",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx1.1",
    product: "Minoxidil topical",
    brand: "Tugain",
    from: "ReliableRxPharmacy",
    price: "40",
    quantity: "120",
    concentration: "10",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=11977",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx2",
    product: "Minoxidil topical",
    brand: "Tugain",
    from: "ReliableRxPharmacy",
    price: "20.50",
    quantity: "60",
    concentration: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4075",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx2.1",
    product: "Minoxidil topical",
    brand: "Tugain",
    from: "ReliableRxPharmacy",
    price: "41",
    quantity: "120",
    concentration: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4075",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx3",
    product: "Minoxidil topical",
    brand: "Mintop",
    from: "ReliableRxPharmacy",
    price: "9.46",
    quantity: "60",
    concentration: "2",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4067",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx3.1",
    product: "Minoxidil topical",
    brand: "Mintop",
    from: "ReliableRxPharmacy",
    price: "18.92",
    quantity: "120",
    concentration: "2",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4067",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx4",
    product: "Minoxidil topical",
    brand: "Coverit",
    from: "ReliableRxPharmacy",
    price: "9.46",
    quantity: "60",
    concentration: "2",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4071",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx4.1",
    product: "Minoxidil topical",
    brand: "Coverit",
    from: "ReliableRxPharmacy",
    price: "18.92",
    quantity: "120",
    concentration: "2",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4071",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx5",
    product: "Minoxidil topical",
    brand: "Hair4u",
    from: "ReliableRxPharmacy",
    price: "22.05",
    quantity: "60",
    concentration: "10",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9619",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx5.1",
    product: "Minoxidil topical",
    brand: "Hair4u",
    from: "ReliableRxPharmacy",
    price: "38.59",
    quantity: "120",
    concentration: "10",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9619",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx6",
    product: "Minoxidil topical",
    brand: "Hair4u",
    from: "ReliableRxPharmacy",
    price: "22.05",
    quantity: "60",
    concentration: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9718",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx6.1",
    product: "Minoxidil topical",
    brand: "Hair4u",
    from: "ReliableRxPharmacy",
    price: "38.59",
    quantity: "120",
    concentration: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9718",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx7",
    product: "Minoxidil topical",
    brand: "Hair4u",
    from: "ReliableRxPharmacy",
    price: "11.03",
    quantity: "60",
    concentration: "2",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9622",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx7.1",
    product: "Minoxidil topical",
    brand: "Hair4u",
    from: "ReliableRxPharmacy",
    price: "17.64",
    quantity: "120",
    concentration: "2",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9622",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "1",
    product: "Minoxidil topical",
    brand: "EssenGen-5",
    from: "MinoxidilMax",
    price: "26",
    quantity: "60",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.minoxidilmax.com/minoxidil_without_propylene_glycol",
    provider_link: "https://www.minoxidilmax.com/products",
  },
  {
    key: "1.1",
    product: "Minoxidil topical",
    brand: "EssenGen-5",
    from: "MinoxidilMax",
    price: "68",
    quantity: "180",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://www.minoxidilmax.com/minoxidil_without_propylene_glycol",
    provider_link: "https://www.minoxidilmax.com/products",
  },
  {
    key: "2",
    product: "Minoxidil topical",
    brand: "Polaris",
    from: "MinoxidilExpress",
    price: "45.90",
    quantity: "60",
    concentration: "12",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/polaris-research-nr11-12-minoxidil-sulfate/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "2.1",
    product: "Minoxidil topical",
    brand: "Polaris",
    from: "MinoxidilExpress",
    price: "129.14",
    quantity: "180",
    concentration: "12",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/polaris-research-nr11-12-minoxidil-sulfate/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "3",
    product: "Minoxidil topical",
    brand: "Regrowth labs",
    from: "MinoxidilExpress",
    price: "35.96",
    quantity: "60",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/regrowth-m5-no-pg-5-minoxidil/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "3.1",
    product: "Minoxidil topical",
    brand: "Regrowth labs",
    from: "MinoxidilExpress",
    price: "100.56",
    quantity: "180",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/regrowth-m5-no-pg-5-minoxidil/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "4",
    product: "Minoxidil topical",
    brand: "Regrowth labs",
    from: "MinoxidilExpress",
    price: "45.90",
    quantity: "60",
    concentration: "15",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/regrowth-labs-m15-no-pg-15-minoxidil/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "4.1",
    product: "Minoxidil topical",
    brand: "Regrowth labs",
    from: "MinoxidilExpress",
    price: "129.14",
    quantity: "180",
    concentration: "15",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/regrowth-labs-m15-no-pg-15-minoxidil/",
    provider_link: "https://minoxidilexpress.com/",
  },
  // NOTE: OUT OF STOCK
  // {
  //   key: "5",
  //   product: "Minoxidil topical",
  //   brand: "Follics",
  //   from: "MinoxidilExpress",
  //   price: "45.90",
  //   quantity: "60",
  //   concentration: "16",
  //   shipping: ["WORLD"],
  //   link: "https://minoxidilexpress.com/product/fr-16-16-minoxidil-cream/",
  //   provider_link: "https://minoxidilexpress.com/",
  // },
  // {
  //   key: "5.1",
  //   product: "Minoxidil topical",
  //   brand: "Follics",
  //   from: "MinoxidilExpress",
  //   price: "129.14",
  //   quantity: "180",
  //   concentration: "16",
  //   shipping: ["WORLD"],
  //   link: "https://minoxidilexpress.com/product/fr-16-16-minoxidil-cream/",
  //   provider_link: "https://minoxidilexpress.com/",
  // },
  {
    key: "6",
    product: "Minoxidil topical",
    brand: "Follics",
    from: "MinoxidilExpress",
    price: "45.90",
    quantity: "60",
    concentration: "15",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/fr-15-15-minoxidil/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "6.1",
    product: "Minoxidil topical",
    brand: "Follics",
    from: "MinoxidilExpress",
    price: "129.14",
    quantity: "180",
    concentration: "15",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/fr-15-15-minoxidil/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "7",
    product: "Minoxidil topical",
    brand: "Follics",
    from: "MinoxidilExpress",
    price: "37.21",
    quantity: "60",
    concentration: "7",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/fr-07-7-minoxidil-treatment/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "7.1",
    product: "Minoxidil topical",
    brand: "Follics",
    from: "MinoxidilExpress",
    price: "103.95",
    quantity: "180",
    concentration: "7",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/fr-07-7-minoxidil-treatment/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "8",
    product: "Minoxidil topical",
    brand: "Follics",
    from: "MinoxidilExpress",
    price: "40.93",
    quantity: "60",
    concentration: "10",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/fr-10-10-minoxidil-treatment/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "8.1",
    product: "Minoxidil topical",
    brand: "Follics",
    from: "MinoxidilExpress",
    price: "112.99",
    quantity: "180",
    concentration: "10",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/fr-10-10-minoxidil-treatment/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "9",
    product: "Minoxidil topical",
    brand: "Regrowth labs",
    from: "MinoxidilExpress",
    price: "29.75",
    quantity: "120",
    concentration: "2",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/regrowth-labs-gel-120ml-strong-hold/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "9.1",
    product: "Minoxidil topical",
    brand: "Regrowth labs",
    from: "MinoxidilExpress",
    price: "83.17",
    quantity: "360",
    concentration: "2",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/regrowth-labs-gel-120ml-strong-hold/",
    provider_link: "https://minoxidilexpress.com/",
  },
  // We don't put this product because it is just worse than product 9 (https://minoxidilexpress.com/product/regrowth-labs-gel-120ml-normal-hold/)
  {
    key: "10",
    product: "Minoxidil topical",
    brand: "Polaris",
    from: "MinoxidilExpress",
    price: "45.90",
    quantity: "60",
    concentration: "15",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/polaris-research-nr09-15/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "10.1",
    product: "Minoxidil topical",
    brand: "Polaris",
    from: "MinoxidilExpress",
    price: "129.14",
    quantity: "180",
    concentration: "15",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/polaris-research-nr09-15/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "11",
    product: "Minoxidil topical",
    brand: "Polaris",
    from: "MinoxidilExpress",
    price: "37.21",
    quantity: "60",
    concentration: "7",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/polaris-research-nr08-7/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "11.1",
    product: "Minoxidil topical",
    brand: "Polaris",
    from: "MinoxidilExpress",
    price: "103.95",
    quantity: "180",
    concentration: "7",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/polaris-research-nr08-7/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "12",
    product: "Minoxidil topical",
    brand: "Polaris",
    from: "MinoxidilExpress",
    price: "34.72",
    quantity: "60",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/polaris-nr07-due-in-stock-30-10-20/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "12.1",
    product: "Minoxidil topical",
    brand: "Polaris",
    from: "MinoxidilExpress",
    price: "96.84",
    quantity: "180",
    concentration: "5",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/polaris-nr07-due-in-stock-30-10-20/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "13",
    product: "Minoxidil topical",
    brand: "Tugain",
    from: "InHousePharmacy",
    price: "34",
    quantity: "60",
    concentration: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-837-tugain-5-solution-minoxidil-5.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "13.1",
    product: "Minoxidil topical",
    brand: "Tugain",
    from: "InHousePharmacy",
    price: "72",
    quantity: "180",
    concentration: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-837-tugain-5-solution-minoxidil-5.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "14",
    product: "Minoxidil topical",
    brand: "Tugain",
    from: "InHousePharmacy",
    price: "40",
    quantity: "60",
    concentration: "10",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1420-tugain-10.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "14.1",
    product: "Minoxidil topical",
    brand: "Tugain",
    from: "InHousePharmacy",
    price: "111",
    quantity: "180",
    concentration: "10",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1420-tugain-10.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
];

export default datasource_minoxidil_topical;

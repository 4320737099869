const datasource_dutasteride_oral = [
  // ReliableRxPharmacy
  {
    key: "1",
    product: "Dutasteride oral",
    brand: "Dutas",
    from: "ReliableRxPharmacy",
    code: "",
    price: 25.23,
    pills: 30,
    unit: 0.5,
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4989",
    provider_link:
      "http://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  // MinoxidilExpress
  {
    key: "2",
    product: "Dutasteride oral",
    brand: "Urodart",
    from: "MinoxidilExpress",
    code: "",
    price: 26.05,
    pills: 28,
    unit: 0.5,
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/urodart-0-5mg/",
    provider_link: "https://minoxidilexpress.com/",
  },
  // InHousePharmacy
  {
    key: "3",
    product: "Dutasteride oral",
    brand: "Dutas",
    from: "InHousePharmacy",
    code: "",
    price: "52.50",
    pills: 30,
    unit: 0.5,
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1161-dutas-capsules-05mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=dutaseride",
  },
  {
    key: "3.1",
    product: "Dutasteride oral",
    brand: "Dutas",
    from: "InHousePharmacy",
    price: "130.50",
    pills: 90,
    unit: 0.5,
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1161-dutas-capsules-05mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=dutaseride",
  },
  {
    key: "4",
    product: "Dutasteride oral",
    brand: "Dutagen",
    from: "InHousePharmacy",
    price: "46.50",
    pills: 30,
    unit: 0.5,
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-570-dutagen-05mg-dutasteride.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=dutaseride",
  },
  {
    key: "4.1",
    product: "Dutasteride oral",
    brand: "Dutagen",
    from: "InHousePharmacy",
    price: "108",
    pills: 90,
    unit: 0.5,
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-570-dutagen-05mg-dutasteride.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=dutaseride",
  },
  {
    key: "5",
    product: "Dutasteride oral",
    brand: "Avodart",
    from: "InHousePharmacy",
    price: "42",
    pills: 30,
    unit: 0.5,
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1906-avodart-dutasteride-05mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=dutaseride",
  },
  {
    key: "5.1",
    product: "Dutasteride oral",
    brand: "Avodart",
    from: "InHousePharmacy",
    price: "112",
    pills: 90,
    unit: 0.5,
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1906-avodart-dutasteride-05mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=dutaseride",
  },
  {
    key: "6",
    product: "Dutasteride oral",
    brand: "Duprost",
    from: "InHousePharmacy",
    price: "47",
    pills: 30,
    unit: 0.5,
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1095-duprost-dutasteride-05mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=dutaseride",
  },
  {
    key: "6.1",
    product: "Dutasteride oral",
    brand: "Duprost",
    from: "InHousePharmacy",
    price: "123",
    pills: 90,
    unit: 0.5,
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1095-duprost-dutasteride-05mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=dutaseride",
  },
];

export default datasource_dutasteride_oral;

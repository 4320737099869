import React from "react";
import { ImArrowUpRight2 } from "react-icons/im";
import { Tag } from "antd";
import "./columns.css";

const zoneToColor = { WORLD: "blue", USA: "purple", UK: "magenta" };

const columns_oral = [
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "From",
    dataIndex: "from",
    key: "from",
    render: (_, data) => (
      <a
        href={data.link}
        target="_blank"
        rel="noreferrer"
        className="anchor-from"
      >
        {data.from} <ImArrowUpRight2 />
      </a>
    ),
  },
  {
    title: "Discount Code",
    dataIndex: "code",
    key: "code",
    render: (_, data) => <>{data.code}</>,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (_, data) => <>${data.price}</>,
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: "Pills",
    dataIndex: "pills",
    key: "pills",
    sorter: (a, b) => a.pills - b.pills,
  },
  {
    title: "Unit",
    dataIndex: "unit",
    key: "unit",
    render: (_, data) => <>{data.unit}mg</>,
    sorter: (a, b) => a.unit - b.unit,
  },
  {
    title: "Price per mg",
    dataIndex: "price_per_mg",
    key: "price per mg",
    render: (_, data) => (
      <>${(data.price / data.pills / data.unit).toFixed(2)}</>
    ),
    sorter: (a, b) =>
      (a.price / a.pills / a.unit).toFixed(2) -
      (b.price / b.pills / b.unit).toFixed(2),
  },
  {
    title: "Shipping",
    dataIndex: "shipping",
    key: "shipping",
    render: (_, data) => {
      return (
        <>
          {data.shipping.map((zone, index) => (
            <Tag color={zoneToColor[zone]} key={index}>
              {zone}
            </Tag>
          ))}
        </>
      );
    },
  },
];

const columns_topical = [
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "From",
    dataIndex: "from",
    key: "from",
    render: (_, data) => (
      <a
        href={data.link}
        target="_blank"
        rel="noreferrer"
        className="anchor-from"
      >
        {data.from} <ImArrowUpRight2 />
      </a>
    ),
  },
  {
    title: "Discount Code",
    dataIndex: "code",
    key: "code",
    render: (_, data) => <>{data.code}</>,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (_, data) => <>${data.price}</>,
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, data) => <>{data.quantity}ml</>,
    sorter: (a, b) => a.quantity - b.quantity,
  },
  {
    title: "Concentration",
    dataIndex: "concentration",
    key: "concentration",
    render: (_, data) => <>{data.concentration}%</>,
    sorter: (a, b) => a.concentration - b.concentration,
  },
  {
    title: "Price per ml",
    dataIndex: "price_per_ml",
    key: "price_per_ml",
    render: (_, data) => <>${(data.price / data.quantity).toFixed(2)}</>,
    sorter: (a, b) =>
      (a.price / a.quantity).toFixed(2) - (b.price / b.quantity).toFixed(2),
  },
  {
    title: "Shipping",
    dataIndex: "shipping",
    key: "shipping",
    render: (_, data) => {
      return (
        <>
          {data.shipping.map((zone, index) => (
            <Tag color={zoneToColor[zone]} key={index}>
              {zone}
            </Tag>
          ))}
        </>
      );
    },
  },
];

export { columns_oral, columns_topical };

const datasource_pyrilutamide_topical = [
  {
    key: "1",
    product: "Pyrilutamide topical",
    brand: "Pyri",
    from: "Hairliciously",
    price: "109.99",
    quantity: "60",
    concentration: "0.5",
    shipping: ["WORLD"],
    link: "https://www.hairliciously.com/products/0-5-topical-pyrilutamide-kx-826-60-ml",
    provider_link: "https://www.hairliciously.com/collections/all",
  },
];

export default datasource_pyrilutamide_topical;

const datasource_minoxidil_oral = [
  {
    key: "rx1",
    product: "Minoxidil oral",
    brand: "Lonitab",
    from: "ReliableRxPharmacy",
    price: "31.19",
    pills: "30",
    unit: "10",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=6887",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx1.1",
    product: "Minoxidil oral",
    brand: "Lonitab",
    from: "ReliableRxPharmacy",
    price: "101.64",
    pills: "90",
    unit: "10",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=6887",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx2",
    product: "Minoxidil oral",
    brand: "Lonitab",
    from: "ReliableRxPharmacy",
    price: "22.52",
    pills: "30",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9259",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "rx2.1",
    product: "Minoxidil oral",
    brand: "Lonitab",
    from: "ReliableRxPharmacy",
    price: "65.84",
    pills: "90",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9259",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "1",
    product: "Minoxidil oral",
    brand: "Lonitab",
    from: "MinoxidilExpress",
    price: "23.77",
    pills: "30",
    unit: "2.5",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/minoxidil-tablets-2-5mg/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "1.1",
    product: "Minoxidil oral",
    brand: "Lonitab",
    from: "MinoxidilExpress",
    price: "63.90",
    pills: "90",
    unit: "2.5",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/minoxidil-tablets-2-5mg/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "2",
    product: "Minoxidil oral",
    brand: "Loniten",
    from: "InHousePharmacy",
    price: "155",
    pills: "100",
    unit: "10",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1412-loniten-10mg-minoxidil.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "2.1",
    product: "Minoxidil oral",
    brand: "Loniten",
    from: "InHousePharmacy",
    price: "580",
    pills: "400",
    unit: "10",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1412-loniten-10mg-minoxidil.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "3",
    product: "Minoxidil oral",
    brand: "Loniten",
    from: "InHousePharmacy",
    price: "66",
    pills: "100",
    unit: "2.5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-99900824-minoxidil-tablets-25mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "3.1",
    product: "Minoxidil oral",
    brand: "Loniten",
    from: "InHousePharmacy",
    price: "114",
    pills: "200",
    unit: "2.5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-99900824-minoxidil-tablets-25mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "4",
    product: "Minoxidil oral",
    brand: "Loniten",
    from: "InHousePharmacy",
    price: "97.50",
    pills: "100",
    unit: "10",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-99900825-minoxidil-10mg-tablets.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "4.1",
    product: "Minoxidil oral",
    brand: "Loniten",
    from: "InHousePharmacy",
    price: "360",
    pills: "400",
    unit: "10",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-99900825-minoxidil-10mg-tablets.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "5",
    product: "Minoxidil oral",
    brand: "Minox",
    from: "InHousePharmacy",
    price: "37",
    pills: "200",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/PIP-Minox-5-mg-2-boxes-p180484007",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "5.1",
    product: "Minoxidil oral",
    brand: "Minox",
    from: "InHousePharmacy",
    price: "72",
    pills: "400",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/PIP-Minox-5-mg-4-boxes-p180476209",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "6",
    product: "Minoxidil oral",
    brand: "Loxidil",
    from: "InHousePharmacy",
    price: "37",
    pills: "200",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/Loxidil-5-mg-2-boxes-p176736972",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "6.1",
    product: "Minoxidil oral",
    brand: "Loxidil",
    from: "InHousePharmacy",
    price: "72",
    pills: "400",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/Loxidil-5-mg-4-boxes-p176737325",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "7",
    product: "Minoxidil oral",
    brand: "Loxidil",
    from: "InHousePharmacy",
    price: "48",
    pills: "200",
    unit: "10",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/Loxidil-10-mg-2-boxes-p176739134",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
  {
    key: "7.1",
    product: "Minoxidil oral",
    brand: "Loxidil",
    from: "InHousePharmacy",
    price: "92",
    pills: "400",
    unit: "10",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/Loxidil-10-mg-4-boxes-p176737313",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=minoxidil",
  },
];

export default datasource_minoxidil_oral;

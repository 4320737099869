const datasource_finasteride_topical = [
  // Hemia Cosmetics
  {
    key: "4",
    product: "Finasteride topical",
    from: "Hemia Cosmetics",
    brand: "Finagen",
    price: "25.86",
    quantity: "50",
    concentration: "0.025",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/finagen-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "4.1",
    product: "Finasteride topical",
    from: "Hemia Cosmetics",
    brand: "Finagen+",
    price: "33.40",
    quantity: "50",
    concentration: "0.1",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/finagen-plus-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "4.2",
    product: "Finasteride topical",
    from: "Hemia Cosmetics",
    brand: "Lipofinagen",
    price: "37.71",
    quantity: "50",
    concentration: "0.025",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/lipofinagen-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "4.3",
    product: "Finasteride topical",
    from: "Hemia Cosmetics",
    brand: "Rufinagen",
    price: "37.71",
    quantity: "50",
    concentration: "0.025",
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/rufinagen-50-ml/",
    provider_link:
      "https://www.hemiacosmetics.com/shop/",
    code: "HAIRDAO10"
  },
  {
    key: "1",
    product: "Finasteride topical",
    brand: "Essengen-F",
    from: "MinoxidilMax",
    price: "116",
    quantity: "60",
    concentration: "0.2",
    shipping: ["WORLD"],
    link: "https://www.minoxidilmax.com/topical-finasteride-without-minoxidil-essengen-f",
    provider_link: "https://www.minoxidilmax.com/products",
  },
  {
    key: "1.1",
    product: "Finasteride topical",
    from: "MinoxidilMax",
    brand: "Essengen-F",
    price: "300",
    quantity: "180",
    concentration: "0.2",
    shipping: ["WORLD"],
    link: "https://www.minoxidilmax.com/topical-finasteride-without-minoxidil-essengen-f",
    provider_link: "https://www.minoxidilmax.com/products",
  },
  {
    key: "2",
    product: "Finasteride topical",
    from: "MinoxidilExpress",
    brand: "Regrowth labs",
    price: "40.93",
    quantity: "60",
    concentration: "0.25",
    shipping: ["WORLD"],
    link: "https://www.minoxidilmax.com/topical-finasteride-without-minoxidil-essengen-f",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "2.1",
    product: "Finasteride topical",
    from: "MinoxidilExpress",
    brand: "Regrowth labs",
    price: "112.99",
    quantity: "180",
    concentration: "0.25",
    shipping: ["WORLD"],
    link: "https://www.minoxidilmax.com/topical-finasteride-without-minoxidil-essengen-f",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "3",
    product: "Finasteride topical",
    from: "InHousePharmacy",
    brand: "Brintop",
    price: "45",
    quantity: "100",
    concentration: "0.1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1890-brintop-f-topical-solution.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "3.1",
    product: "Finasteride topical",
    from: "InHousePharmacy",
    brand: "Brintop",
    price: "86",
    quantity: "200",
    concentration: "0.1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1890-brintop-f-topical-solution.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "3.2",
    product: "Finasteride topical",
    from: "InHousePharmacy",
    brand: "Brintop",
    price: "126",
    quantity: "300",
    concentration: "0.1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1890-brintop-f-topical-solution.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
];

export default datasource_finasteride_topical;

import Button from '../Button'
import logo from '../../assets/images/logo.png'
import patients from '../../assets/images/patients.png'
import user from '../../assets/images/user.png'

const Header = () => {
  return (
    <div className="flex justify-between items-center relative">
      <a href="/">
        <img src={logo} alt="Logo" width={200} layout="fixed" />
        <img
          src={patients}
          alt="Patients"
          width={120}
          layout="fixed"
          className="absolute top-8 left-32"
        />
      </a>
      <div className="flex">
        <Button value="ABOUT" href="https://www.hairdao.xyz/about-hairdao"/>
        <Button value="PROJECT PIPELINE" href="https://www.hairdao.xyz/project-pipeline"/>
        <Button value="JOIN HAIRDAO" href="https://www.hairdao.xyz/community"/>
        <Button value="GET FUNDING" href="https://www.hairdao.xyz/apply-for-funding"/>
        <Button value="VOTE" href="https://gov.hairdao.xyz/#/"/>
        <Button value="PATIENT PORTAL" href="https://patient.hairdao.xyz/" icon={user}/>
      </div>
    </div>
  )
}

export default Header

import React from 'react'
// import Footer from './Footer'
import Header from './Header'

const Layout = ({ children }) => {
  return (
    <div className='container mx-auto py-6 font-gt-america'>
      <Header />
      {children}
      {/* <Footer /> */}
    </div>
  )
}

export default Layout;
const datasource_dutasteride_topical = [
  // Hemia Cosmetics
  {
    key: "hc",
    product: "Dutasteride topical",
    brand: "Dutagen",
    from: "Hemia Cosmetics",
    price: 49.57,
    quantity: 50,
    concentration: 0.1, 
    shipping: ["WORLD"],
    link: "https://www.hemiacosmetics.com/product/dutagen/",
    provider_link: "https://www.hemiacosmetics.com/shop",
    code: "HAIRDAO10",
  },
  // MinoxidilMax
  {
    key: "1",
    product: "Dutasteride topical",
    brand: "Duderma",
    from: "MinoxidilMax",
    price: 50,
    quantity: 60,
    concentration: 0.1,
    shipping: ["WORLD"],
    link: "https://www.minoxidilmax.com/topical-dutasteride-without-minoxidil-Duderma",
    provider_link: "https://www.minoxidilmax.com/products",
  },
  {
    key: "1.1",
    product: "Dutasteride topical",
    brand: "Duderma",
    from: "MinoxidilMax",
    price: 120,
    quantity: 180,
    concentration: 0.1,
    shipping: ["WORLD"],
    link: "https://www.minoxidilmax.com/topical-dutasteride-without-minoxidil-Duderma",
    provider_link: "https://www.minoxidilmax.com/products",
  },
];

export default datasource_dutasteride_topical;

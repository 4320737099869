const datasource_finasteride_oral = [
  {
    key: "1",
    product: "Finasteride oral",
    brand: "Fincar",
    from: "ReliableRxPharmacy",
    code:"",
    price: "17.73",
    pills: "30",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4039",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "1.1",
    product: "Finasteride oral",
    brand: "Fincar",
    from: "ReliableRxPharmacy",
    price: "53.21",
    pills: "90",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4039",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "2",
    product: "Finasteride oral",
    brand: "Veltam",
    from: "ReliableRxPharmacy",
    price: "18.92",
    pills: "30",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=11610",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "2.1",
    product: "Finasteride oral",
    brand: "Veltam",
    from: "ReliableRxPharmacy",
    price: "56.76",
    pills: "90",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=11610",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "3",
    product: "Finasteride oral",
    brand: "Finpecia",
    from: "ReliableRxPharmacy",
    price: "12",
    pills: "30",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4043",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "3.1",
    product: "Finasteride oral",
    brand: "Finpecia",
    from: "ReliableRxPharmacy",
    price: "25",
    pills: "90",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=4043",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  // NOTE: Propecia is out of stock, check later: https://www.reliablerxpharmacy.com/propecia-1mg.html
  {
    key: "4",
    product: "Finasteride oral",
    brand: "Finast",
    from: "ReliableRxPharmacy",
    price: "17.73",
    pills: "30",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9965",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "4.1",
    product: "Finasteride oral",
    brand: "Finast",
    from: "ReliableRxPharmacy",
    price: "53.21",
    pills: "90",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=9965",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "45.1",
    product: "Finasteride oral",
    brand: "Finalo",
    from: "ReliableRxPharmacy",
    price: "10",
    pills: "30",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=11610",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "45.2",
    product: "Finasteride oral",
    brand: "Finalo",
    from: "ReliableRxPharmacy",
    price: "27",
    pills: "100",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=11610",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  {
    key: "45.3",
    product: "Finasteride oral",
    brand: "Finalo",
    from: "ReliableRxPharmacy",
    price: "54",
    pills: "200",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.reliablerxpharmacy.com/affiliate/product/share?mw_aref=801f45fd4aadf32145d126f6d753479b&product_id=11610",
    provider_link:
      "https://www.reliablerxpharmacy.com/?mw_aref=801f45fd4aadf32145d126f6d753479b",
  },
  // MinoxidilExpress
  {
    key: "5",
    product: "Finasteride oral",
    from: "MinoxidilExpress",
    brand: "Accord",
    price: "28.78",
    pills: "30",
    unit: "1",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/accord-1mg-uk-made/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "5.1",
    product: "Finasteride oral",
    from: "MinoxidilExpress",
    brand: "Accord",
    price: "76.44",
    pills: "90",
    unit: "1",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/accord-1mg-uk-made/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "6",
    product: "Finasteride oral",
    from: "MinoxidilExpress",
    brand: "Pronor",
    price: "28.78",
    pills: "30",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/pronor-5mg/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "6.1",
    product: "Finasteride oral",
    from: "MinoxidilExpress",
    brand: "Pronor",
    price: "76.44",
    pills: "90",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/pronor-5mg/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "7",
    product: "Finasteride oral",
    from: "MinoxidilExpress",
    brand: "Recur",
    price: "23.77",
    pills: "30",
    unit: "1",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/recur-1mg/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "7.1",
    product: "Finasteride oral",
    from: "MinoxidilExpress",
    brand: "Recur",
    price: "63.90",
    pills: "90",
    unit: "1",
    shipping: ["WORLD"],
    link: "https://minoxidilexpress.com/product/recur-1mg/",
    provider_link: "https://minoxidilexpress.com/",
  },
  {
    key: "8",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Finast",
    price: "40",
    pills: "30",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-2080-finast-finasteride-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "8.1",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Finast",
    price: "70.75",
    pills: "60",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-2080-finast-finasteride-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "8.2",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Finast",
    price: "99.95",
    pills: "90",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-2080-finast-finasteride-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "9",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Finax",
    price: "29.50",
    pills: "30",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1089-finax-finasteride-1mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "9.1",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Finax",
    price: "48",
    pills: "60",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1089-finax-finasteride-1mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "9.2",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Finax",
    price: "61.80",
    pills: "90",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1089-finax-finasteride-1mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  // Only put 30 and 180 tablets
  // NOTE: OUT OF STOCK
  // {
  //   key: "10",
  //   product: "Finasteride oral",
  //   from: "InHousePharmacy",
  //   brand: "Finpecia",
  //   price: "28.75",
  //   pills: "30",
  //   unit: "1",
  //   shipping: ["USA", "UK"],
  //   link: "https://www.inhousepharmacy.vu/p-179-finpecia-tablets-1mg.aspx",
  //   provider_link:
  //     "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  // },
  // {
  //   key: "10.1",
  //   product: "Finasteride oral",
  //   from: "InHousePharmacy",
  //   brand: "Finpecia",
  //   price: "99",
  //   pills: "180",
  //   unit: "1",
  //   shipping: ["USA", "UK"],
  //   link: "https://www.inhousepharmacy.vu/p-179-finpecia-tablets-1mg.aspx",
  //   provider_link:
  //     "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  // },
  {
    key: "11",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Fincar",
    price: "32",
    pills: "30",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-178-fincar-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "11.1",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Fincar",
    price: "89",
    pills: "90",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-178-fincar-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "12",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Propecia",
    price: "98",
    pills: "28",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-344-propecia-tablets-1mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "12.1",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Propecia",
    price: "256",
    pills: "84",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-344-propecia-tablets-1mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "13",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Prosteride",
    price: "29.50",
    pills: "30",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-2081-prosteride-tablets-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "13.1",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Prosteride",
    price: "74.50",
    pills: "90",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-2081-prosteride-tablets-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "14",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Ricit",
    price: "88",
    pills: "100",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-2044-ricit-tablets-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "15",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Proscar",
    price: "67.50",
    pills: "28",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1916-proscar-finasteride-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "15.1",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Proscar",
    price: "177",
    pills: "84",
    unit: "5",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-1916-proscar-finasteride-5mg.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "16",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Finpecia",
    price: "28.75",
    pills: "30",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-112680801-finpecia-1mg-finasteride15-tabletsstrip.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "16.1",
    product: "Finasteride oral",
    from: "InHousePharmacy",
    brand: "Finpecia",
    price: "99",
    pills: "180",
    unit: "1",
    shipping: ["USA", "UK"],
    link: "https://www.inhousepharmacy.vu/p-112680801-finpecia-1mg-finasteride15-tabletsstrip.aspx",
    provider_link:
      "https://www.inhousepharmacy.vu/search.aspx?searchterm=finasteride",
  },
  {
    key: "17",
    product: "Finasteride oral",
    from: "GoodStuffStore",
    brand: "Firide",
    price: "40",
    pills: "30",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/Firide-5-mg-Hair-Growth-Prevent-Hair-Loss-30-tablets-p177306549",
    provider_link:
      "https://www.goodstuffstore.net/store/search?keyword=finasteride",
  },
  {
    key: "17.1",
    product: "Finasteride oral",
    from: "GoodStuffStore",
    brand: "Firide",
    price: "76",
    pills: "60",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/2-boxes-Firide-5-mg-Hair-Growth-Prevent-Hair-Loss-30-tablets-p180950097",
    provider_link:
      "https://www.goodstuffstore.net/store/search?keyword=finasteride",
  },
  {
    key: "18",
    product: "Finasteride oral",
    from: "GoodStuffStore",
    brand: "Harifin",
    price: "39",
    pills: "30",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/Harifin-5-5-mg-p180950264",
    provider_link:
      "https://www.goodstuffstore.net/store/search?keyword=finasteride",
  },
  {
    key: "18.1",
    product: "Finasteride oral",
    from: "GoodStuffStore",
    brand: "Harifin",
    price: "76",
    pills: "60",
    unit: "5",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/2-boxes-Harifin-5-5-mg-p180953059",
    provider_link:
      "https://www.goodstuffstore.net/store/search?keyword=finasteride",
  },
  {
    key: "19",
    product: "Finasteride oral",
    from: "GoodStuffStore",
    brand: "Harifin",
    price: "30",
    pills: "30",
    unit: "1",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/Harifin-1-1-mg-p180950131",
    provider_link:
      "https://www.goodstuffstore.net/store/search?keyword=finasteride",
  },
  {
    key: "19.1",
    product: "Finasteride oral",
    from: "GoodStuffStore",
    brand: "Harifin",
    price: "58",
    pills: "60",
    unit: "1",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/2-boxes-Harifin-1-1-mg-p180943639",
    provider_link:
      "https://www.goodstuffstore.net/store/search?keyword=finasteride",
  },
  {
    key: "20",
    product: "Finasteride oral",
    from: "GoodStuffStore",
    brand: "Firide",
    price: "38",
    pills: "30",
    unit: "1",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/Firide-1-mg-Hair-Growth-Prevent-Hair-Loss-30-tablets-p177303694",
    provider_link:
      "https://www.goodstuffstore.net/store/search?keyword=finasteride",
  },
  {
    key: "20.1",
    product: "Finasteride oral",
    from: "GoodStuffStore",
    brand: "Firide",
    price: "73",
    pills: "60",
    unit: "1",
    shipping: ["WORLD"],
    link: "https://www.goodstuffstore.net/store/2-boxes-Firide-1-mg-Hair-Growth-Prevent-Hair-Loss-30-tablets-p180943518",
    provider_link:
      "https://www.goodstuffstore.net/store/search?keyword=finasteride",
  },
];

export default datasource_finasteride_oral;

const Button = (props) => {
  const ButtonType = {
    Normal: 0,
    Active: 1,
    Save: 2
  }

  const { value, icon, type, handleClick, href, active } = props

  return (
    <>
      {href ? (
        <a
          className={`flex items-center border rounded-3xl py-2 px-6 leading-8 w-fit mx-2 cursor-pointer hover:bg-regal-blue hover:border-regal-blue hover:text-regal-white ${
            type === ButtonType.Active
              ? 'border-regal-blue bg-regal-blue text-black'
              : 'border-black'
          } ${
            active
              ? 'border-regal-blue bg-regal-blue text-regal-white'
              : 'border-black'
          }`}
          href={href}
        >
          {icon && (
            <img
              src={icon}
              alt="Icon"
              width={20}
              layout="fixed"
              className="mr-2 h-fit"
            />
          )}
          <div className="text-lg font-medium uppercase">{value}</div>
        </a>
      ) : (
        <a
          className={`flex items-center border rounded-3xl py-2 px-6 leading-8 w-fit mx-2 cursor-pointer hover:bg-regal-blue hover:border-regal-blue hover:text-regal-white ${
            type === ButtonType.Active
              ? 'border-regal-blue bg-regal-blue text-black'
              : 'border-black'
          } ${
            active
              ? 'border-regal-blue bg-regal-blue text-regal-white'
              : 'border-black'
          }`}
          onClick={handleClick}
          href={href}
        >
          {icon && (
            <img
              src={icon}
              alt="Icon"
              width={20}
              layout="fixed"
              className="mr-2 h-fit"
            />
          )}
          <div className="text-lg font-medium uppercase">{value}</div>
        </a>
      )}
    </>
  )
}

export default Button

import React, { useState } from "react";
import { Table, Select, Tabs } from "antd";
import datasource_finasteride_oral from "../../data/datasources/finasteride_oral";
import datasource_finasteride_topical from "../../data/datasources/finasteride_topical";
import datasource_minoxidil_oral from "../../data/datasources/minoxidil_oral";
import datasource_minoxidil_topical from "../../data/datasources/minoxidil_topical";
import datasource_pyrilutamide_topical from "../../data/datasources/pyrilutamide_topical";
import datasource_dutasteride_oral from "../../data/datasources/dutasteride_oral";
import datasource_dutasteride_topical from "../../data/datasources/dutasteride_topical";
import datasource_ru58841_topical from "../../data/datasources/ru58841_topical";
import { columns_oral, columns_topical } from "../../data/columns";
import { ImArrowUpRight2 } from "react-icons/im";
import { FaSearch } from "react-icons/fa";

const zoneToShippingDisplay = {
  USA: "🇺🇸 USA",
  EUROPE: "🇪🇺 EUROPE",
  UK: "🇬🇧 UK",
};

const productToDatasource = {
  "Finasteride oral": datasource_finasteride_oral,
  "Finasteride topical": datasource_finasteride_topical,
  "Dutasteride oral": datasource_dutasteride_oral,
  "Dutasteride topical": datasource_dutasteride_topical,
  "Minoxidil oral": datasource_minoxidil_oral,
  "Minoxidil topical": datasource_minoxidil_topical,
  "RU58841 topical": datasource_ru58841_topical,
  "Pyrilutamide topical": datasource_pyrilutamide_topical,
};

function shippingDisplayToZone(shippingDisplay) {
  return shippingDisplay.split(" ")[1];
}

const isMobileOrTablet = (() => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
})();

function Dashboard() {
  const [product, setProduct] = useState("Finasteride oral");
  const [shipping, setShipping] = useState("USA");
  const [filteredDatasource, setFilteredDatasource] = useState(
    productToDatasource[product].filter(
      (data) =>
        data.product === product &&
        (data.shipping.includes(shipping) || data.shipping.includes("WORLD"))
    )
  );

  const cheapestPricePerQuantity = Math.min(
    ...filteredDatasource.map((data) =>
      data.product.includes("oral")
        ? (data.price / data.pills / data.unit).toFixed(2)
        : (data.price / data.quantity).toFixed(2)
    )
  );

  const cheapestProducts = filteredDatasource.filter((data) =>
    data.product.includes("oral")
      ? // eslint-disable-next-line eqeqeq
        (data.price / data.pills / data.unit).toFixed(2) ==
        cheapestPricePerQuantity
      : // eslint-disable-next-line eqeqeq
        (data.price / data.quantity).toFixed(2) == cheapestPricePerQuantity
  );

  const providerLinksFiltered = filteredDatasource
    .map((data) => ({
      provider_link: data.provider_link,
      from: data.from,
    }))
    .filter(
      (data, index, array) =>
        array.findIndex((t) => data.provider_link === t.provider_link) === index
    );

  return (
    <main>
      <div className="app pt-16">
        <div className="flex gap-2 px-16">
          <div className="pt-2">
            <FaSearch className="search-logo" />
          </div>
          <div>
            <h1 className="font-bold text-4xl font-itf-editor">Find hairloss products</h1>
            <p className="subtitle">without prescription</p>
          </div>
        </div>
        <hr className="mt-8 mb-6"/>
        <div className="px-16">
          <div className="select-wrapper">
            <div className="select-product-wrapper">
              <h4 className="select-product-title">Select hairloss product</h4>
              <Select
                bordered={false}
                className="select-product"
                placeholder="Finasteride oral"
                size="large"
                showSearch={!isMobileOrTablet}
                onChange={(product) => {
                  setProduct(product);
                  setFilteredDatasource(() =>
                    productToDatasource[product].filter(
                      (data) =>
                        data.product === product &&
                        (data.shipping.includes(shipping) ||
                          data.shipping.includes("WORLD"))
                    )
                  );
                }}
                options={[
                  { value: "Finasteride oral", label: "Finasteride oral" },
                  { value: "Finasteride topical", label: "Finasteride topical" },
                  { value: "Dutasteride oral", label: "Dutasteride oral" },
                  { value: "Dutasteride topical", label: "Dutasteride topical" },
                  { value: "Minoxidil oral", label: "Minoxidil oral" },
                  { value: "Minoxidil topical", label: "Minoxidil topical" },
                  { value: "RU58841 topical", label: "RU58841 topical" },
                  {
                    value: "Pyrilutamide topical",
                    label: "Pyrilutamide topical",
                  },
                ]}
              ></Select>
            </div>
            <div className="select-shipping-wrapper">
              <h4 className="select-shipping-title">Shipping</h4>
              <Select
                bordered={false}
                className="select-shipping"
                defaultValue={zoneToShippingDisplay[shipping]}
                size="large"
                showSearch={!isMobileOrTablet}
                onChange={(selectedShipping) => {
                  setShipping(shippingDisplayToZone(selectedShipping));
                  setFilteredDatasource(() =>
                    productToDatasource[product].filter(
                      (data) =>
                        data.product === product &&
                        (data.shipping.includes(
                          shippingDisplayToZone(selectedShipping)
                        ) ||
                          data.shipping.includes("WORLD"))
                    )
                  );
                }}
                options={[
                  { value: "🇺🇸 USA" },
                  { value: "🇪🇺 EUROPE" },
                  { value: "🇬🇧 UK" },
                ]}
              ></Select>
            </div>
          </div>
          <div className="show-results-from">
            <p className="show-results-from-text">Show results from</p>
            {providerLinksFiltered.map((data, index) => (
              <a
                key={index}
                className="show-results-from-link"
                href={data.provider_link}
                rel="noreferrer"
                target="_blank"
              >
                {data.from} <ImArrowUpRight2 />
              </a>
            ))}
          </div>
          <Tabs
            type="card"
            size="large"
            items={[
              {
                key: "1",
                label: `All`,
                children: (
                  <div className="table-wrapper">
                    <Table
                      dataSource={filteredDatasource}
                      columns={
                        product.includes("oral") ? columns_oral : columns_topical
                      }
                      expandable={{ indentSize: 0 }}
                      scroll={{ x: 820 }}
                    ></Table>
                  </div>
                ),
              },
              {
                key: "2",
                label: "Cheapest",
                children: (
                  <div className="table-wrapper">
                    <Table
                      dataSource={cheapestProducts}
                      columns={
                        product.includes("oral") ? columns_oral : columns_topical
                      }
                      expandable={{ indentSize: 0 }}
                      scroll={{ x: 820 }}
                    ></Table>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
